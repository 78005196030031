
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { AppActionTypes } from "@/store/modules/app/types";

export default defineComponent({
  setup() {
    const store = useStore();
    store.dispatch(AppActionTypes.ACTION_REPORT_YEAR_LIST);
    return {};
  },
});
